import Drawer from '@mui/material/Drawer'
import { ReactNode } from 'react'

export interface MobileNavDrawerProps {
  opened: boolean
  onClose: () => void
  children: ReactNode
  announcementBarHeight: number
  headerHeight: {
    xs: number
    md: number
    lg: number
    xl: number
  }
}
export default function MobileNavDrawer({
  announcementBarHeight,
  headerHeight,
  children,
  opened,
  onClose,
}: MobileNavDrawerProps) {
  return (
    <Drawer
      open={opened}
      anchor="top"
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        top: {
          xs: `${headerHeight.xs + announcementBarHeight}px`,
          md: `${headerHeight.md + announcementBarHeight}px`,
          lg: `${headerHeight.lg + announcementBarHeight}px`,
          xl: `${headerHeight.xl + announcementBarHeight}px`,
        },
        zIndex: 1155,
        '&>.MuiPaper-root': {
          transition:
            'transform opacity 225ms cubic-bezier(0, 0, 0.2, 1) !important',
          opacity: opened ? 1 : 0,
          visibility: 'visible',
          top: {
            xs: `${headerHeight.xs + announcementBarHeight}px`,
            md: `${headerHeight.md + announcementBarHeight}px`,
            lg: `${headerHeight.lg + announcementBarHeight}px`,
            xl: `${headerHeight.xl + announcementBarHeight}px`,
          },
          zIndex: 1155,
          bottom: 0,
          width: '100%',
        },
      }}
    >
      {children}
    </Drawer>
  )
}
